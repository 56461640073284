import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

import { useGetProduct } from "../../hooks/useGetProduct";
import { useDispatchCart } from "../../controllers/Cart";
import { useCart } from "../../controllers/Cart";
import { useUser } from "../../controllers/UserContext";
import { useAuth } from "../../controllers/useAuth";
import { useLanguage } from "../../controllers/Language";

// import Rating from '../../components/Rating'
import Quantity from "../../components/Quantity";
import Warning from "../../components/Static/Warning";

export default function Product() {
  const productId = useParams();
  const id = parseInt(productId.id);
  const numberId = parseInt(id);
  console.log(numberId);

  const Language = useLanguage();

  const dispatch = useDispatchCart();

  const { prod: p, isPennding, err } = useGetProduct(id);

  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [type, setType] = useState();
  const [brutto, setBrutto] = useState(0);
  const [netto, setNetto] = useState(0);
  const [bruttoDiscount, setBruttoDiscount] = useState(0);
  const [nettoDiscount, setNettoDiscount] = useState(0);
  // const [activeWarning, setActiveWarning] = useState(false);

  // console.log(activeWarning);

  const promoActionState = sessionStorage.getItem("promoActionState");
  const userType = sessionStorage.getItem("userType");
  let discount_value = "0.00";

  if (product) {
    switch (userType) {
      case "partner30":
        discount_value = product.discount_value;
        break;
      case "rdz2":
        discount_value = product.discount_value_rdz;
        break;
      case "rdz":
        discount_value = product.discount_value_rdz;
        break;
      case "rdz3":
        discount_value = product.discount_value_rdz;
        break;
      case "client":
        discount_value = product.discount_value;
        break;
      default:
        discount_value = product.discount_value;
        break;
    }
  }

  // useEffect(() => {
  //   switch (numberId) {
  //     case 105:
  //       setActiveWarning(true);
  //       break;
  //     case 106:
  //       setActiveWarning(true);
  //       break;
  //     case 107:
  //       setActiveWarning(true);
  //       break;
  //     case 108:
  //       setActiveWarning(true);
  //       break;
  //     case 109:
  //       setActiveWarning(true);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [numberId]);

  const userCart = useCart();

  const userLoggedIn = useAuth();

  const userObject = useUser();

  const productInCart = userCart.find((item) => {
    return item.productId === id;
  });

  useEffect(() => {
    if (userObject && userObject.user) {
      setType(userObject.user.userType);
    }
  }, [userObject]);

  useEffect(() => {
    setProduct(p);
  }, [p]);

  useEffect(() => {
    if (product) {
      if (product.category === "Personal Care" || product.id === 139) {
        switch (type) {
          case "partner30":
            setBrutto(product.brutto_a);
            setNetto(product.netto_a);
            break;
          case "client":
            setBrutto(product.brutto_c);
            setNetto(product.netto_c);
            break;
          case "rdz":
            setBrutto(product.brutto_b);
            setNetto(product.netto_b);
            break;
          case "rdz2":
            setBrutto(product.brutto_d);
            setNetto(product.netto_d);
            break;
          case "rdz3":
            setBrutto(product.brutto_e);
            setNetto(product.netto_e);
            break;
          default:
            setBrutto(product.brutto);
            setNetto(product.netto);
            break;
        }
      } else {
        switch (type) {
          case "partner30":
            setBrutto(product.brutto_a);
            setNetto(product.netto_a);
            break;
          case "rdz":
            setBrutto(product.brutto_b);
            setNetto(product.netto_b);
            break;
          case "rdz2":
            setBrutto(product.brutto_d);
            setNetto(product.netto_d);
            break;
          case "rdz3":
            setBrutto(product.brutto_e);
            setNetto(product.netto_e);
            break;
          default:
            setBrutto(product.brutto);
            setNetto(product.netto);
            break;
        }
      }
    }
  }, [product, type]);

  const handleQuantityChange = (direction) => {
    if (direction === "minus") {
      setQuantity((prevQuantity) => prevQuantity - 1);
    } else if (direction === "plus") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  useEffect(() => {
    if (product) {
      setTotalPrice(parseFloat(brutto) * quantity);
    } else {
      setTotalPrice(0);
    }
  }, [product, quantity, brutto]);

  useEffect(() => {
    if (discount_value !== "0.00") {
      if (product?.discount_type === "percent") {
        const newBrutto = Math.round(product.brutto * (parseFloat(discount_value) / 100) * 100).toFixed(2);
        setBruttoDiscount(newBrutto);
        setNettoDiscount((Math.floor((newBrutto / 1.23) * 100) / 100).toFixed(2));
      } else {
        setBruttoDiscount(parseFloat(discount_value));
        setNettoDiscount((Math.floor((parseFloat(discount_value) / 1.23) * 100) / 100).toFixed(2));
      }
    } else {
      setBruttoDiscount(0);
      setNettoDiscount(0);
    }
  }, [product, discount_value, brutto]);

  let cartPayload;

  if (product) {
    cartPayload = {
      productId: product.id,
      name: product.name,
      short_name: product.short_name,
      brutto: discount_value === "0.00" ? brutto : bruttoDiscount,
      netto: discount_value === "0.00" ? netto : nettoDiscount,
      vat: product.vat,
      quantity: quantity,
      ref_number: product.ref_number,
      weight: product.weight,
    };
  }

  const addToCart = (item) => {
    dispatch({ type: "ADD", item });
  };

  useEffect(() => {
    dispatch({ type: "READ" });
  }, [dispatch]);

  const variants = {
    active: {
      scale: 0.95,
      rotate: -3,
      transition: {
        duration: 0.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      {userLoggedIn ? (
        <>
          {err ? (
            <div className="error_msg">{err}</div>
          ) : !product || isPennding ? (
            <h2>Ładowanie...</h2>
          ) : (
            <div className="mt4">
              <div className="single__product">
                <div className="single__img">
                  <motion.div
                    className="product__img"
                    style={{ backgroundImage: `url('${product.main_img}')` }}
                    whileHover="active"
                    variants={variants}
                  ></motion.div>
                </div>
                <div className="single__info">
                  <h2 className="single__name">{product.name}</h2>
                  <>
                    {discount_value === "0.00" ? (
                      <>{!product.brutto ? <h3>...</h3> : <h2 className={`single__product__price ${Language}`}>{totalPrice}</h2>}</>
                    ) : (
                      <>
                        {!product.brutto ? (
                          <h3>...</h3>
                        ) : (
                          <div className="flex gap-10">
                            <h2 className={`single__product__price single__product__price_new ${Language}`}>{bruttoDiscount * quantity}</h2>
                            <h2 className={`single__product__price single__product__price_old ${Language}`}>{totalPrice}</h2>
                          </div>
                        )}
                      </>
                    )}
                    {promoActionState === "active" && discount_value !== "0.00" && <p className="product__price">Cena detaliczna na 30 dni przed obniżką: {product.brutto} zł</p>}
                  </>

                  {/* <div className='single__ratings'>
                  <Rating rating={product.rating}/>
                </div> */}

                  <p className="single__category">{product.category}</p>
                  <p>{product.description}</p>
                  {product.stock > 0 ? (
                    <div>
                      <Quantity handleQuantityChange={handleQuantityChange}>{quantity}</Quantity>
                      {!productInCart ? (
                        <button
                          onClick={() => addToCart(cartPayload)}
                          className="btn-alert w100"
                        >
                          Dodaj do Koszyka
                        </button>
                      ) : (
                        <NavLink
                          to={`/koszyk`}
                          className="w100"
                        >
                          <button className="btn-alert w100">Zobacz koszyk</button>
                        </NavLink>
                      )}
                      <br />
                      <NavLink
                        to={`/`}
                        className="w100"
                      >
                        <button className="btn-alert w100 marginTop">Wszystkie produkty</button>
                      </NavLink>
                    </div>
                  ) : (
                    <div className="text-tl-accent font-bold text-lg text-center">Produkt chwilowo niedostępny</div>
                  )}
                </div>
              </div>
              <br />
              <h3 className="mt4">Szczegóły:</h3>
              <div
                className="single__description"
                dangerouslySetInnerHTML={{ __html: product.full_description }}
              />
              {/* {activeWarning === true ? <Warning /> : <></>} */}
            </div>
          )}
        </>
      ) : (
        <>
          {err ? (
            <div>{err}</div>
          ) : !product || isPennding ? (
            <h2>Ładowanie...</h2>
          ) : (
            <>
              <div className="mt4">
                <div className="single__product">
                  <div className="single__img">
                    <motion.div
                      className="product__img"
                      style={{ backgroundImage: `url('${product.main_img}')` }}
                      whileHover="active"
                      variants={variants}
                    ></motion.div>
                  </div>
                  <div className="single__info">
                    <h2 className="single__name">{product.name}</h2>

                    {/* <div className='single__ratings'>
                  <Rating rating={product.rating}/>
                </div> */}

                    <p className="single__category">{product.category}</p>
                    <div
                      className="single__description"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                    <NavLink
                      to={`/login`}
                      className="w100"
                    >
                      <button className="btn-alert w100">Zaloguj się, aby zobaczyć ceny</button>
                    </NavLink>
                    <br />
                    <NavLink
                      to={`/`}
                      className="w100"
                    >
                      <button className="btn-alert w100 marginTop">Wszystkie produkty</button>
                    </NavLink>
                  </div>
                </div>
                <br />
                <h3 className="mt4">Szczegóły:</h3>
                <div
                  className="single__description"
                  dangerouslySetInnerHTML={{ __html: product.full_description }}
                ></div>
                {/* {activeWarning === true ? <Warning /> : <></>} */}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
